<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header>
        <h1>{{ $t('message.change_password') }}</h1>
      </header>

      <div class="mb-4">
        <p>
          {{ $t('message.fill_out_form') }}
        </p>
      </div>
      <div id="password_form">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="checkForm"
          aria-autocomplete="off"
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <v-text-field
            autocomplete="current-password"
            v-model="currentPassword"
            :rules="currentPasswordRules"
            :label="$t('message.current_password')"
            type="password"
          ></v-text-field>

          <v-text-field
            v-model="newPassword"
            :rules="newPasswordRules"
            @input="passwordCheck"
            :label="$t('message.new_password')"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            autocomplete="new-password"
          ></v-text-field>

          <div class="d-flex">
            <span class="mr-3 font-weight-bold text-danger" style="color: darkred;">{{ $t('message.weak') }}</span>
            <password
              v-model="newPassword"
              :secureLength="10"
              :strength-meter-only="true"
            />
            <span class="ml-3 font-weight-bold text-success" style="color: darkred;">{{ $t('message.strong') }}</span>
          </div>

          <span class="font-weight-bold">{{ $t('message.minimal_password_level') }}</span><br>
          <span class="frmValidation" :class="{'frmValidation--passed' : newPassword.length > 9 && newPassword.length <= 32}"><i class="frmIcon fas" :class="newPassword.length > 9 && newPassword.length <= 32 ? 'fa-check' : 'fa-times'"></i> {{ $t('message.between_10_32_char') }}</span><br>
          <span class="frmValidation" :class="{'frmValidation--passed' :hasUppercase }"><i class="frmIcon fas" :class="hasUppercase ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_caps') }}</span><br>
          <span class="frmValidation" :class="{'frmValidation--passed' :hasLowercase }"><i class="frmIcon fas" :class="hasLowercase ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_lowercase') }}</span><br>
          <span class="frmValidation" :class="{'frmValidation--passed' : hasNumber }"><i class="frmIcon fas" :class="hasNumber ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_digit') }}</span><br>
          <span class="frmValidation" :class="{'frmValidation--passed' : hasSpecialChar }"><i class="frmIcon fas" :class="hasSpecialChar ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_special_char') }}</span><br>

          <v-text-field
            v-model="confirmNewPassword"
            :rules="confirmNewPasswordRules"
            class="mt-3"
            :label="$t('message.confirm_new_password')"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="new-password"
          ></v-text-field>

          <button type="submit" :disabled="!valid" class="btn btn-primary float-right mt-4">
            <i class="fas fa-check-circle"></i>{{ $t('message.validate') }}
          </button>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Password from 'vue-password-strength-meter'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'

export default {
  name: 'UpdatePassword',
  components: { Password },
  data: () => ({
    valid: false,
    showPassword: false,
    successMessage: null,
    errors: [],
    loading: false,
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    hasNumber: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSpecialChar: false
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - ' + this.$t('message.change_password')
  },
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    currentPasswordRules () {
      return [
        v => !!v || this.$t('message.mandatory_current_password')
      ]
    },
    newPasswordRules () {
      return [
        v => !!v || this.$t('message.mandatory_password')
      ]
    },
    confirmNewPasswordRules () {
      return [
        v => !!v || this.$t('message.mandatory_password_confirmation')
      ]
    }
  },
  methods: {
    checkForm () {
      const user = this.$store.getters.getUser

      if (this.newPassword !== this.confirmNewPassword) {
        this.errors = [{ title: this.$t('message.password_must_match') }]

        return
      }

      if (this.newPassword.toLowerCase() === user.email.toLowerCase()) {
        this.errors = [{ title: this.$t('message.password_must_be_different_email') }]

        return
      }

      if (this.newPassword.length < 10 || !this.hasNumber || !this.hasLowercase || !this.hasUppercase || !this.hasSpecialChar) {
        this.errors.push({ title: this.$t('message.password_not_strong_enough') })

        return
      }

      if (this.valid) {
        this.updatePassword()
      }
    },
    passwordCheck () {
      this.hasNumber = /\d/.test(this.newPassword)
      this.hasLowercase = /[a-z]/.test(this.newPassword)
      this.hasUppercase = /[A-Z]/.test(this.newPassword)
      this.hasSpecialChar = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword)
    },
    updatePassword () {
      const userId = this.$store.getters.getUser.id

      const data = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      this.loading = true

      Vue.prototype.$http
        .post(config.apiUrl + '/update-password/' + userId, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            _method: 'PUT'
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data
              this.errors = []
            }
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }

          this.loading = false
        })
    }
  }
}
</script>
